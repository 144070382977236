// src/data.js

export const songs = [
  {
    "ReleaseDate": "2024/11/24",
    "title": "barraca",
    "info": "集落や小さな村で見る夕陽をイメージをして作った曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/4z4iHsKidNE",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/barraca.mp3",
    "tags": [
      "rock",
      "暗い",
      "ゆっくり",
      "おしゃれ",
      "tony",
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "Break Time",
    "info": "カフェで流れてそうな、チルした雰囲気の曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/P0mNmNPOphA",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/BreakTime.mp3",
    "tags": [
      "chill",
      "暗い",
      "ゆっくり",
      "リラックス",
      "のんびり",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "Clever",
    "info": "頭脳戦の戦闘をイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/6YXIvj698D4",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/Clever.mp3",
    "tags": [
      "rock",
      "暗い",
      "早い",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/11/24",
    "title": "crazy pop",
    "info": "かっこ可愛いをイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/gMt98vfs8oY",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/crazypop.mp3",
    "tags": [
      "edm",
      "暗い",
      "早い",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "Crime",
    "info": "衝撃のラストシーンをイメージしたRock調の曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/mh4JWjyeVSk",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/Crime.mp3",
    "tags": [
      "rock",
      "暗い",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/11/24",
    "title": "darkness",
    "info": "FPSゲームの緊張感ある世界観をイメージして制作した曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/qCK9EzkjK74",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/darkness.mp3",
    "tags": [
      "edm",
      "暗い",
      "早い",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/10/27",
    "title": "Daydream Street Pianists",
    "info": "スラム街の1人のピアニストが広場のピアノを弾き、他の楽器は全て想像というイメージです",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/oRGLKhX3UvI",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/DaydreamStreetPianists.mp3",
    "tags": [
      "edm",
      "暗い",
      "早い",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "despair",
    "info": "絶望した人をイメージした暗い雰囲気の曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/ieRxBP_kBdw",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/despair.mp3",
    "tags": [
      "edm",
      "暗い",
      "ゆっくり",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "Dobble Lucky",
    "info": "ラスボスと戦っているBGMをイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/2iWgMvnP5jE",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/DobbleLucky.mp3",
    "tags": [
      "rock",
      "明るい",
      "早い",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "elegant-morning",
    "info": "優雅な朝をイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/EJhLi61XeXw",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/elegant-morning.mp3",
    "tags": [
      "カントリー",
      "明るい",
      "ゆっくり",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "fall in dark",
    "info": "ミステリー、ホラーで使えそうな暗い雰囲気の曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/PUpRkv6O16Y",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/fallindark.mp3",
    "tags": [
      "edm",
      "暗い",
      "ゆっくり",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "Fast Think",
    "info": "脳内思考を巡らせているイメージの曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/sCTCXEafZKE",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/FastThink.mp3",
    "tags": [
      "funk",
      "明るい",
      "おしゃれ",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/11/24",
    "title": "FreakOut",
    "info": "高級ホテルの料理人達の忙しい厨房をイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/q5sXf_ojCVc",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/FreakOut.mp3",
    "tags": [
      "edm",
      "暗い",
      "早い",
      "おしゃれ",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/10/27",
    "title": "Ghost Party",
    "info": "お化けが踊ってパーティしているイメージの曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/HlAKBMNqUQ8",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/GhostParty.mp3",
    "tags": [
      "edm",
      "明るい",
      "早い",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/11/24",
    "title": "gravity",
    "info": "重力の強い力をイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/H-LBMiFA724",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/gravity.mp3",
    "tags": [
      "rock",
      "暗い",
      "ゆっくり",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "Impatience",
    "info": "レトロゲームのピコピコ感をイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/l_Hy6RxDRLc",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/Impatience.mp3",
    "tags": [
      "edm",
      "暗い",
      "早い",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "Jingle Bell Jazz cover",
    "info": "クリスマス曲のジングルベルのジャズバージョンカバーです",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/6LDg1nkXsYc",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/JingleBell_Jazzcover.mp3",
    "tags": [
      "jazz",
      "明るい",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "Jingle Bell Rock cover",
    "info": "クリスマス曲のジングルベルのROCK風カバーです",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/oHIBYYbOHGU",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/JingleBell_Rockcover.mp3",
    "tags": [
      "rock",
      "明るい",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2025/01/18",
    "title": "Kind World",
    "info": "優しい雰囲気の空間をイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/jeBcgJcLydc",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/Kind+World.mp3",
    "tags": [
      "pop",
      "明るい",
      "のんびり",
      "tony",
      "新曲"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "landing step",
    "info": "ラジオのBGMをイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/246Uri_wTfw",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/landingstep.mp3",
    "tags": [
      "funk",
      "明るい",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2025/01/18",
    "title": "like",
    "info": "懐かしくて温かい記憶を思い出す場面をイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/w8XCgPNFRz8",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/like.mp3",
    "tags": [
      "pop",
      "明るい",
      "ゆっくり",
      "tony",
      "新曲"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "Night Right",
    "info": "おしゃれなラジオのEDをイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/c5fhP6sX2ro",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/NightRight.mp3",
    "tags": [
      "edm",
      "明るい",
      "おしゃれ",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/10/27",
    "title": "Pretty Hop",
    "info": "可愛い動物達が跳ね周っている感じの曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/lfg-fU9k4v4",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/PrettyHop.mp3",
    "tags": [
      "edm",
      "明るい",
      "早い",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "RACE",
    "info": "車で峠を攻めているシーンをイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/valygI5NLQY",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/RACE.mp3",
    "tags": [
      "edm",
      "暗い",
      "早い",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "Revenge",
    "info": "何度繰り返し失敗しても、再挑戦しようとする人をイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/BgLJSWlNoK8",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/Revenge.mp3",
    "tags": [
      "edm",
      "明るい",
      "早い",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/10/27",
    "title": "RUSH!",
    "info": "激しい戦闘しているような荒々しい曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/LuVtJAdeGwo",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/RUSH!.mp3",
    "tags": [
      "rock",
      "暗い",
      "早い",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/11/24",
    "title": "Rust Earth",
    "info": "オープンワールドのサバイバルゲームのような世界観をイメージして制作した曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/mpRDEr8koPk",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/RustEarth.mp3",
    "tags": [
      "カントリー",
      "明るい",
      "ゆっくり",
      "リラックス",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/29",
    "title": "Second Challenge",
    "info": "ROCKに重く早く強くって感じの戦闘シーンをイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/A5-bn7nBjlE",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/SecondChallenge.mp3",
    "tags": [
      "rock",
      "暗い",
      "早い",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "Snake",
    "info": "雑談ラジオのBGMで使えそうなイメージの曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/cNjyItgGTpo",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/Snake.mp3",
    "tags": [
      "カントリー",
      "明るい",
      "リラックス",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "snow",
    "info": "部屋の中からのんびり雪を眺める雰囲気の曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/xBH3gWlGW0c",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/snow.mp3",
    "tags": [
      "edm",
      "明るい",
      "ゆっくり",
      "リラックス",
      "のんびり",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "Start Dash",
    "info": "走り出しの始まりの疾走感をイメージしたロックな曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/IOobrnXivHg",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/StartDash.mp3",
    "tags": [
      "rock",
      "明るい",
      "早い",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "TRY",
    "info": "電子音多めのyoutube等のOPをイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/D0EHSFHmwlw",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/TRY.mp3",
    "tags": [
      "edm",
      "明るい",
      "ゆっくり",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "TV",
    "info": "TVの画面が切り替わっていくようなイメージをした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/r779GUmV7P8",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/TV.mp3",
    "tags": [
      "edm",
      "明るい",
      "早い",
      "声あり",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/11/24",
    "title": "waiting",
    "info": "ラジオのトークや配信の待ち時間をイメージして作った曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/UMudM4XiRuU",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/waiting.mp3",
    "tags": [
      "カントリー",
      "明るい",
      "のんびり",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2025/01/18",
    "title": "Winter to Spring",
    "info": "冬から春に向けて走り抜けるイメージをした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/3BLcS3HeGiE",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/Winter+to+Spring.mp3",
    "tags": [
      "rock",
      "明るい",
      "早い",
      "tony",
      "新曲"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "YaYa",
    "info": "やや気の抜けたBGMです",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/nyOyREYtThs",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/YaYa.mp3",
    "tags": [
      "edm",
      "明るい",
      "ゆっくり",
      "リラックス",
      "のんびり",
      "74zarashi"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "アオハル",
    "info": "青春をイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/DTZ1hk5VOqs",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/%E3%82%A2%E3%82%AA%E3%83%8F%E3%83%AB.mp3",
    "tags": [
      "rock",
      "明るい",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/10/27",
    "title": "アメのちハレ",
    "info": "雨降っているところから徐々に天気が良くなっていくように、段々明るくなる曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/TQuU3jgBwgo",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/%E3%82%A2%E3%83%A1%E3%81%AE%E3%81%A1%E3%83%8F%E3%83%AC.mp3",
    "tags": [
      "edm",
      "暗い",
      "早い",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/10/27",
    "title": "一日はいつだって早い",
    "info": "一日は本当に早い、ニワトリが朝を告げ、羊が夜を知らせて終わっちゃう一日の曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/YZDniA6d2jE",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/%E4%B8%80%E6%97%A5%E3%81%AF%E3%81%84%E3%81%A4%E3%81%A0%E3%81%A3%E3%81%A6%E6%97%A9%E3%81%84.mp3",
    "tags": [
      "edm",
      "明るい",
      "ゆっくり",
      "のんびり",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/29",
    "title": "空",
    "info": "緊張感やスピード感があるアクションゲームやバトル開始前をイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/54yG-dIea-w",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/%E7%A9%BA.mp3",
    "tags": [
      "rock",
      "明るい",
      "早い",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2025/01/18",
    "title": "桜花-ARCADE",
    "info": "2Dゲームの春のステージ雰囲気をイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/o77JWJnAo4I",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/%E6%A1%9C%E8%8A%B1-ARCADE.mp3",
    "tags": [
      "edm",
      "明るい",
      "早い",
      "ゲーム",
      "tony",
      "新曲"
    ]
  },
  {
    "ReleaseDate": "2025/01/18",
    "title": "桜花-朱",
    "info": "和風テイストの戦闘シーンをイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/hkXLQzrCk-g",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/%E6%A1%9C%E8%8A%B1-%E6%9C%B1.mp3",
    "tags": [
      "edm",
      "暗い",
      "早い",
      "ゲーム",
      "tony",
      "新曲"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "散歩",
    "info": "少女がルンルン歩いているイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/tnmL0LrJFCo",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/%E6%95%A3%E6%AD%A9.mp3",
    "tags": [
      "pop",
      "明るい",
      "ゆっくり",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "森羅万象",
    "info": "近未来の乗り物で森を駆け抜けるイメージの曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/26PygHQOS4I",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/%E6%A3%AE%E7%BE%85%E4%B8%87%E8%B1%A1.mp3",
    "tags": [
      "edm",
      "明るい",
      "早い",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/11/24",
    "title": "雪合戦",
    "info": "元気な冬の雪合戦の風景をイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/Rp9CDAWnjbk",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/%E9%9B%AA%E5%90%88%E6%88%A6.mp3",
    "tags": [
      "pop",
      "明るい",
      "早い",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/11/24",
    "title": "雪道の馬車",
    "info": "静かな旅や幻想的なシーン、落ち着いた雰囲気の曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/Sbsddq206sg",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/%E9%9B%AA%E9%81%93%E3%81%AE%E9%A6%AC%E8%BB%8A.mp3",
    "tags": [
      "カントリー",
      "明るい",
      "ゆっくり",
      "リラックス",
      "のんびり",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "大騒動",
    "info": "朝遅刻しそうで、急いでる時の姿をイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/ECLvtOz-7JY",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/%E5%A4%A7%E9%A8%92%E5%8B%95.mp3",
    "tags": [
      "カントリー",
      "明るい",
      "早い",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "眠り姫",
    "info": "眠ったままの姫をイメージして、夢見気分な感じの曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/ptgq86gr350",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/%E7%9C%A0%E3%82%8A%E5%A7%AB.mp3",
    "tags": [
      "カントリー",
      "明るい",
      "早い",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "明日から本気出す",
    "info": "今日じゃなくて、明日から本気出す予定という、やる気のない雰囲気の曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/I5iR9hXEAak",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/%E6%98%8E%E6%97%A5%E3%81%8B%E3%82%89%E6%9C%AC%E6%B0%97%E5%87%BA%E3%81%99.mp3",
    "tags": [
      "カントリー",
      "明るい",
      "ゆっくり",
      "リラックス",
      "のんびり",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/29",
    "title": "夜桜",
    "info": "夜の桜の雰囲気をイメージした曲です。",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/jaqyIFyTIq4",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/%E5%A4%9C%E6%A1%9C.mp3",
    "tags": [
      "edm",
      "暗い",
      "ゆっくり",
      "リラックス",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "74ROOM",
    "info": "ゲーマーのチルな部屋をイメージした曲です",
    "youtubeEmbedUrl": "https://www.youtube.com/embed/cSqKDRBLipg",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/74ROOM.mp3",
    "tags": [
      "edm",
      "明るい",
      "ゆっくり",
      "リラックス",
      "のんびり",
      "74zarashi"
    ]
  }
];
