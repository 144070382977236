import React, { useState, useEffect } from 'react';
import './RotatingVideos.css';

// 以下で最新動画を指定してHOME画面に表示する
const RotatingVideos = () => {
  const videoUrls = [
    'https://www.youtube.com/embed/o77JWJnAo4I',
    'https://www.youtube.com/embed/jeBcgJcLydc',
    'https://www.youtube.com/embed/3BLcS3HeGiE'
  ];

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [transitioning, setTransitioning] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setTransitioning(true);
      setTimeout(() => {
        setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoUrls.length);
        setTransitioning(false);
      }, 500); // スライドアニメーションの期間
    }, 15000); // 動画の切り替え間隔（5000ミリ秒 = 5秒）

    return () => clearInterval(interval);
  }, [videoUrls.length]);

  return (
    <div className={`video-container ${transitioning ? 'sliding' : ''}`}>
      <iframe
        width="420"
        height="315"
        src={videoUrls[currentVideoIndex]}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default RotatingVideos;
